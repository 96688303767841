import React from "react"
import PropTypes from 'prop-types'

import "./anmeldung-cta-section.sass"
import Link from "gatsby-link"

const AnmeldungCtaSection = ({claimIndex}) => {
  const claims = ['Es ist Zeit, das Steuer in die Hand zu nehmen!', 'Eine Welt voller Freiheit wartet auf dich!', 'Manche Schlüssel öffnen nur Türen. Andere Welten.']
  return <div className="_72">
    <div className={'_13'}>
      <div className="_73 _16">

        <h3 className={'_74 _4e'}>
          {claims[claimIndex]}
        </h3>
        <Link to={'/anmeldung/'} className="_as _4t _4u">Jetzt
          Anmelden</Link>
      </div>
    </div>
  </div>
}

AnmeldungCtaSection.propTypes = {
  claimIndex: PropTypes.number
}

AnmeldungCtaSection.defaultProps = {
  claimIndex: 0
}

export default AnmeldungCtaSection
