import React, { useState } from "react"
import AccordionItem from "./accordion-item"
import "./accordion.sass"
import PropTypes from "prop-types"

const Accordion = ({ questionsAnswers }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
    const showDescription = index === activeIndex
    const ariaExpanded = index === activeIndex ? "true" : "false"
    return (
      <AccordionItem
        showDescription={showDescription}
        ariaExpanded={ariaExpanded}
        item={item}
        index={index}
        onClick={() => {
          setActiveIndex(index)
        }}
      />
    )
  })

  return (
    <section className="_13">

    <div className="_81 _17">
      <h3 className="_4d _83">Häufig gestellte Fragen</h3>
      <dl className="_ar">{renderedQuestionsAnswers}</dl>
    </div>
    </section>
  )
}

Accordion.propTypes = {
  questionsAnswers: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.node,
  })),
}

export default Accordion
