import React from "react"
import { Parallax, Background } from "react-parallax"
import Img from "gatsby-image"

import './hero.sass';

const Hero = ({fluid}) => {
  return <Parallax
    strength={500}
    className={"_6l"}
  >
    <Background>

      <Img alt={'Deine Fahrschule in Backnang — Fahrschule Rauscher.'} className={'_6n'} fluid={fluid}></Img>
    </Background>
    </Parallax>
}

export default Hero;
