import React from "react"

import "./hero-text.sass"

const HeroText = () => {
  return <div className="_70 _13">
    <div className={"_4d _71"}>Fahrschule Rauscher</div>
  </div>
}

export default HeroText
