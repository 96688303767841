import React from "react";

import './about.sass';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const About = () => {
  const {file} = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "fahrschule-rauscher-backnang.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  return <section className="_7k">
    <div className="_13">
      <div className="_15 _7m">
          <div className="_7o">
            <h1 className="_4d _7p">Fahrschule Rauscher<div className={"_4g mt-4"}>Deine Fahrschule in Backnang</div></h1>

            <p>Bereits seit zwei Generationen kümmern wir uns darum, Menschen erfolgreich in den Verkehr zu bringen. Dabei war für meinen Vater und mich sowohl bei unserer Gründung im Jahr 2012 als auch meiner vier Jahre späteren Übernahme klar, dass wir einen anderen Ansatz verfolgen als andere Fahrschulen:<br/><br/>Mit uns hast du nicht nur garantiert jede Menge Spaß und den Führerschein in der Tasche, sondern lernst vor allem im Verkehr zu bestehen. Schließlich sieht man sich spätestens auf der Straße wieder.</p>

          </div>
          <div className="_7n">
            <Img alt={'Die Fahrschule Rauscher steht für Qualität. Und das schon in der zweiten Generation.'} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} imgStyle={{height: '100%', bottom: '0', top: 'unset'}} fluid={file.childImageSharp.fluid}></Img>
          </div>
      </div>
    </div>

    <div className="_7l"/>
  </section>
}

export default About;
